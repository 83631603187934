@import '~@csstools/normalize.css';

:root {
  --wrapper-xs: calc(100vw - 2 * 20px);
  --wrapper-l: calc(100vw - 120px);
  --wrapper-xl: 1000px;
  --white: #fff;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: normal;
  src: url('./fontFiles/ApercuPro-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Apercu Pro';
  src: url('./fontFiles/ApercuPro-Bold.woff2') format('woff2');
}

body {
  background: var(--color-sem-canvas-neutral-default-1);
  color: var(--color-sem-text-neutral-bold);
  margin: 0;
}

ul li {
  padding: 0;
}

.tab-focus {
  &:focus-visible {
    outline: var(--border-width-ref-1) solid var(--color-sem-focus-default);
    outline-offset: var(--border-radius-ref-1);
    z-index: 3000;
  }
}

.tab-focus-internal {
  &:focus-visible {
    outline: var(--border-width-ref-1) solid var(--color-sem-focus-default);
    outline-offset: -1px;
    z-index: 3000;
  }
}

.tab-focus-all-elems {
  & [contenteditable],
  [tabindex],
  a,
  area,
  audio,
  button,
  details,
  iframe,
  input,
  select,
  summary,
  textarea,
  video {
    &:focus-visible {
      outline: var(--border-width-ref-1) solid var(--color-sem-focus-default);
      outline-offset: var(--space-sem-padding-2xsmall);
      z-index: 3000;
    }
  }
}
