@import '../../../../theme/breakpoints';

.navbar {
  display: none;

  // Hide for mobile & Tablet
  @media #{$large} {
    display: flex;
    flex-grow: 1;
    height: 100%;

    ul {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }
  }
}
