@import '../../../../theme/breakpoints';

.inline-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 var(--space-ref-5, 16px);
  scrollbar-width: none;
  white-space: nowrap;
  width: 100%;
}

.item-container {
  align-items: center;
  display: flex;
  height: var(--size-ref-12, 48px);
  justify-content: center;
  padding: 0 var(--size-ref-2);
  text-transform: var(--text-typeset-text-case-upper);

  &:last-child {
    padding-right: var(--size-ref-5);
  }

  @media #{$large} {
    display: none;

    &:nth-child(-n + 7) {
      display: flex;
    }
  }

  @media #{$xlarge} {
    &:nth-child(-n + 8) {
      display: flex;
    }
  }
}

.item {
  color: var(--color-sem-link-neutral-default);
  cursor: grab;
  font-size: var(--text-typeset-font-size-2xs, 13px);
  font-weight: var(--text-typeset-font-weights-normal-600);
  padding: 0 var(--size-ref-1);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.grabbing {
    cursor: grabbing;
  }
}

.secondary-nav-container {
  align-items: center;
  background-color: var(--color-com-header-secondary-nav-container);
  border-bottom: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  display: flex;
  height: 33px;
  justify-content: center;
  margin: auto;
  max-width: var(--wrapper-l);
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  z-index: auto;

  @media #{$small} {
    max-width: var(--wrapper-xs);
  }

  @media #{$medium} {
    margin-left: 0;
    margin-right: 0;
    max-width: var(--wrapper-l);
    width: 100%;

    &::after {
      background: var(--color-sem-border-neutral-subtle);
      content: ' ';
      height: 70%;
      position: absolute;
      right: 0;
      top: 30%;
      width: var(--border-width-ref-2);
    }
  }

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);

    &::before {
      background: var(--color-sem-border-neutral-subtle);
      content: ' ';
      height: 70%;
      left: 0;
      position: absolute;
      top: 30%;
      width: var(--border-width-ref-2);
    }
  }

  &.curtain-open {
    background-color: var(--color-sem-surface-neutral-subtle);
    border-top: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    height: auto;

    .inline-container {
      flex-direction: column;
      margin: 0;
      padding: 0;

      .item-container {
        border-bottom: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
        color: var(--color-sem-link-neutral-default);
        width: 100%;

        .item {
          cursor: pointer;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
